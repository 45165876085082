import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from './store'

let routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import(/* webpackPrefetch: true */ './views/Landing'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/iniciarsesion/:policy?',
    name: 'Login',
    component: () => import(/* webpackPrefetch: true */ './views/Login'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    },
    beforeEnter: (to, from, next) => {
      // Has logged in.
      if (store.getters.auth.access_token) {
        next('/perfil')
      } else {
        next()
      }
    }
  },
  {
    path: '/recuperar/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackPrefetch: true */ './views/ResetPassword'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    },
    beforeEnter: (to, from, next) => {
      // Has logged in.
      if (store.getters.auth.access_token) {
        next('/perfil')
      } else {
        next()
      }
    }
  },
  {
    path: '/verificar/correo/:token',
    name: 'ConfirmEmail',
    component: () => import(/* webpackPrefetch: true */ './views/ConfirmEmail'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/perfil',
    name: 'Profile',
    component: () => import(/* webpackPrefetch: true */ './views/Profile'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    },
    beforeEnter: (to, from, next) => {
      // Hasn't logged in.
      if (!store.getters.auth.access_token) {
        next('/perfil')
      } else {
        next()
      }
    },
    redirect: '/perfil/usuario',
    children: [
      {
        path: 'usuario',
        component: () => import(/* webpackPrefetch: true */ './views/ProfileUser'),
        meta: {
          header_background: 'white',
          header_text: 'secondary'
        },
        name: 'ProfileUser'
      },
      {
        path: 'polizas',
        component: () => import(/* webpackPrefetch: true */ './views/ProfilePolicies'),
        meta: {
          header_background: 'white',
          header_text: 'secondary'
        },
        name: 'ProfilePolicies'
      }
    ]
  },
  {
    path: '/registro/:policy?',
    name: 'Register',
    component: () => import(/* webpackPrefetch: true */ './views/Register'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    },
    beforeEnter: (to, from, next) => {
      // Has logged in.
      if (store.getters.auth.access_token) {
        next('/perfil')
      } else {
        next()
      }
    }
  },
  {
    path: '/auto',
    name: 'ProductCar',
    component: () => import(/* webpackPrefetch: true */ './views/ProductCar'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/gnp',
    name: 'ProductGNP',
    component: () => import(/* webpackPrefetch: true */ './views/ProductGNP'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/flotillas',
    name: 'ProductFleet',
    component: () => import(/* webpackPrefetch: true */ './views/ProductFleet'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/hogar',
    name: 'ProductHouse',
    component: () => import(/* webpackPrefetch: true */ './views/ProductHouse'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/vida',
    name: 'ProductLife',
    component: () => import(/* webpackPrefetch: true */ './views/ProductLife'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/uber',
    name: 'ProductUber',
    component: () => import(/* webpackPrefetch: true */ './views/ProductUber'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/cotizar/:type/:id',
    name: 'QuoteResults',
    component: () => import(/* webpackPrefetch: true */ './views/QuoteResults'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/emitir/:type/:quote/:product/:frequency/:quote_price/:policy?',
    name: 'Issuance',
    component: () => import(/* webpackPrefetch: true */ './views/Issuance'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/checkout/:type/:policy',
    name: 'Checkout',
    component: () => import(/* webpackPrefetch: true */ './views/Checkout')
  },
  {
    path: '/pagar/efectivo/:type/:policy',
    name: 'PayWithCash',
    component: () => import(/* webpackPrefetch: true */ './views/PayWithCash')
  },
  {
    path: '/nosotros',
    name: 'About',
    component: () => import(/* webpackPrefetch: true */ './views/About')
  },
  {
    path: '/aseguradoras',
    name: 'Companies',
    component: () => import(/* webpackPrefetch: true */ './views/Companies')
  },
  {
    path: '/comofunciona',
    name: 'HowItWorks',
    component: () => import(/* webpackPrefetch: true */ './views/HowItWorks'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/politicasdeprivacidad',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackPrefetch: true */ './views/PrivacyPolicy')
  },
  {
    path: '/terminosycondiciones',
    name: 'Terms',
    component: () => import(/* webpackPrefetch: true */ './views/Terms')
  },
  {
    path: '/siniestros',
    name: 'Sinisters',
    component: () => import(/* webpackPrefetch: true */ './views/Sinisters')
  },
  {
    path: '/preguntasfrecuentes',
    name: 'FAQ',
    component: () => import(/* webpackPrefetch: true */ './views/FAQ')
  },
  {
    path: '/gracias/:policy?',
    name: 'Thankyou',
    component: () => import(/* webpackPrefetch: true */ './views/Thankyou'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/buscar/poliza',
    name: 'PolicySearch',
    component: () => import(/* webpackPrefetch: true */ './views/PolicySearch'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/poliza/:policy?',
    name: 'Policy',
    component: () => import(/* webpackPrefetch: true */ './views/Policy'),
    meta: {
      header_background: 'white',
      header_text: 'secondary'
    }
  },
  {
    path: '/notfound',
    component: () => import(/* webpackPrefetch: true */ './views/NotFound')
  },
  {
    path: '*',
    component: () => import(/* webpackPrefetch: true */ './views/NotFound')
  }
]

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: routes
})

export default router
