import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: process.env.VUE_APP_COLOR_PRIMARY,
    secondary: process.env.VUE_APP_COLOR_SECONDARY,
    accent: process.env.VUE_APP_COLOR_ACCENT,
    pantene: process.env.VUE_APP_COLOR_PANTENE,
    'light-background': process.env.VUE_APP_COLOR_LIGHT_BACKGROUND,
    'dark-grey': process.env.VUE_APP_COLOR_DARK_GREY,
    'green-cash': process.env.VUE_APP_COLOR_GREEN_CASH
  }
})
