<script>
  export default {
    data () {
      return {
        fontSizesAndLineHeighs: [115, 70, 60, 45, 40, 38, 36, 35, 32, 30, 28, 27, 25, 22, 20, 19, 18, 17, 16, 15, 14, 12, 11, 10],
        maxAndMinWidthsAndHeights: [25, 40, 140, 150, 200, 250, 300, 350, 400, 500, 600, 700, 900, 164, 1200, 1400],
        margins: [-140, 80]
      }
    },
    methods: {
      // font-x
      generateFontSize (value) {
        return `
          .font-${value} {
            font-size: ${value}px !important;
          }
        `
      },
      // line-height-x
      generateLineHeight (value) {
        return `
          .line-height-${value} {
            line-height: ${value}px !important;
          }
        `
      },
      // max-width-x
      generateMaxWidth (value) {
        return `
          .max-width-${value} {
            max-width: ${value}px !important;
          }
        `
      },
      // max-height-x
      generateMaxHeight (value) {
        return `
          .max-height-${value} {
            max-height: ${value}px !important;
          }
        `
      },
      // min-width-x
      generateMinWidth (value) {
        return `
          .min-width-${value} {
            min-width: ${value}px !important;
          }
        `
      },
      // min-height-x
      generateMinHeight (value) {
        return `
          .min-height-${value} {
            min-height: ${value}px !important;
          }
        `
      },
      // margin-top-x
      generateMarginTop (value) {
        return `
          .margin-top-${value} {
            margin-top: ${value}px !important;
          }
        `
      },
      // margin-right-x
      generateMarginRight (value) {
        return `
          .margin-right-${value} {
            margin-right: ${value}px !important;
          }
        `
      },
      // margin-bottom-x
      generateMarginBottom (value) {
        return `
          .margin-bottom-${value} {
            margin-bottom: ${value}px !important;
          }
        `
      },
      // margin-left-x
      generateMarginLeft (value) {
        return `
          .margin-left-${value} {
            margin-left: ${value}px !important;
          }
        `
      },
      applyStyle (styleDef) {
        let style = document.createElement('style')
        style.type = 'text/css'
        document.head.appendChild(style)
        style.innerHTML = styleDef
      }
    },
    created () {
      this.fontSizesAndLineHeighs.map(size => {
        this.applyStyle(this.generateFontSize(size))
        this.applyStyle(this.generateLineHeight(size))
      })

      this.margins.map(size => {
        this.applyStyle(this.generateMarginTop(size))
        this.applyStyle(this.generateMarginRight(size))
        this.applyStyle(this.generateMarginBottom(size))
        this.applyStyle(this.generateMarginLeft(size))
      })

      this.maxAndMinWidthsAndHeights.map(value => {
        this.applyStyle(this.generateMaxWidth(value))
        this.applyStyle(this.generateMaxHeight(value))
        this.applyStyle(this.generateMinWidth(value))
        this.applyStyle(this.generateMinHeight(value))
      })
    }
  }
</script>