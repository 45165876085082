import Vue from 'vue'

export default {
  getCarModels ( callback, errorHandler) {
    return Vue.http.get('public/car/model').then(callback, errorHandler)
  },
  getCarBrands (model, callback, errorHandler) {
    return Vue.http.get('public/car/brand/' + model).then(callback, errorHandler)
  },
  getCarVersions (model, brand, callback, errorHandler) {
    return Vue.http.get('public/car/version/' + model + '/' + brand).then(callback, errorHandler)
  },
  createCarQuote (payload, callback, errorHandler) {
    return Vue.http.post('public/car/quote', payload).then(callback, errorHandler)
  },
  getCarQuote (uuid, callback, errorHandler) {
    return Vue.http.get('public/car/quote/' + uuid).then(callback, errorHandler)
  },
  updateCarQuote (id, payload, callback, errorHandler) {
    return Vue.http.patch('public/car/quote/' + id, payload).then(callback, errorHandler)
  },
  getCarQuoteByCompany (company, quote, payment, level, payment_type, callback, errorHandler) {
    return Vue.http.get('public/car/quote/' + company + '/' + quote + '/' + payment + '/' + level + '/' + payment_type).then(callback, errorHandler)
  },
  getCarQuotePDF (id, callback, errorHandler) {
    return Vue.http.get('public/car/pdf/' + id).then(callback, errorHandler)
  }
}
