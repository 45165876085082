<template>
  <v-app>
    <global-header></global-header>
    <v-content>
      <router-view :key="$route.fullPath" />
    </v-content>

    <global-footer></global-footer>

    <message-dialog :dialog="g_dialog"></message-dialog>
  </v-app>
</template>

<script>
import CSSGenerator from '@/mixins/CSSGenerator'

export default {
  name: 'App',
  mixins: [CSSGenerator],
  components: {
    'global-header': () => import(/* webpackPrefetch: true */ './views/Header'),
    'global-footer': () => import(/* webpackPrefetch: true */ './views/Footer')
  },
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_LANDING,
    titleTemplate: process.env.VUE_APP_METATAGS_TITLE_TEMPLATE,
    meta: [
      // General
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_LANDING
      },
      {
        name: 'name',
        content: process.env.VUE_APP_METATAGS_NAME
      },

      // Open Graph (Facebook)
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_LANDING
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: process.env.VUE_APP_METATAGS_URL + process.env.VUE_APP_METATAGS_IMAGE
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_LANDING
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:site_name',
        content: process.env.VUE_APP_METATAGS_NAME
      },

      // Twitter
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_LANDING
      },
      {
        vmid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary'
      },
      {
        vmid: 'twitter:image',
        property: 'twitter:image',
        content: process.env.VUE_APP_METATAGS_URL + process.env.VUE_APP_METATAGS_IMAGE
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_LANDING
      },
      {
        property: 'twitter:site',
        content: process.env.VUE_APP_METATAGS_TWITTER_USER
      }
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL
      }
    ]
  },
  methods: {
    sendCampaingParams () {
      this.g_isLoading = true
      let payload = {
        utm_source: this.$route.query.utm_source,
        utm_medium: this.$route.query.utm_medium,
        utm_campaign: this.$route.query.utm_campaign,
        utm_content: this.$route.query.utm_content,
        buyer: this.$route.query.buyer,
        gclid: this.$route.query.gclid,
        fbclid: this.$route.query.fbclid
      }
      this.$api.sendCampaingParams(
        payload,
        response => {
          localStorage.setItem('radial', response.body.data.uuid)
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.sendCampaingParams()
        }, () => {})
      )
    }
  },
  watch: {
    '$route.query': {
      handler () {
        if (this.$route.query && (this.$route.query.buyer || this.$route.query.utm_campaign || this.$route.query.utm_content || this.$route.query.utm_medium || this.$route.query.utm_source || this.$route.query.gclid || this.$route.query.fbclid)) {
          this.sendCampaingParams()
        }
      },
      deep: true
    }
  },
  mounted () {
    if (typeof (Event) === 'function') {
      document.dispatchEvent(new Event('render-event'))
    }

    if (this.$route.query && (this.$route.query.buyer || this.$route.query.utm_campaign || this.$route.query.utm_content || this.$route.query.utm_medium || this.$route.query.utm_source)) {
      this.sendCampaingParams()
    }
  }
}
</script>

<style>
/*********/
/* Fonts */
/*********/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  font-display: fallback;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Graphik-Light';
  src: url('./assets/fonts/Graphik-Light.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Graphik-BoldItalic';
  src: url('./assets/fonts/Graphik-BoldItalic.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Graphik-Medium';
  src: url('./assets/fonts/Graphik-Medium.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Graphik-Bold';
  src: url('./assets/fonts/Graphik-Bold.woff') format('woff');
  font-display: fallback;
}

/************/
/* Defaults */
/************/
::-webkit-scrollbar {
  background: #FFFFFF;
  height: 8px;
  width: 10px
}

::-webkit-scrollbar-thumb {
  border: none;
  -webkit-box-shadow:none;
  box-shadow:none;
  background:#DDDDDD;
  min-height:40px
}

:hover::-webkit-scrollbar-thumb {
  background:#BDBDBD
}

::-webkit-scrollbar-thumb:hover {
  background:#AAAAAA
}

::-webkit-scrollbar-thumb:active{
  background:#989898
}

::-moz-selection {
  background: #4C3AA1;
  color: #fff;
}

::selection {
  background: #4C3AA1;
  color: #fff;
}

.application, .application.theme--light, html {
  font-family: 'Graphik-Light';
  background: #ffffff;
}

h1, h2, h3, p {
  font-size: inherit;
  font-weight: inherit;
  color: #848484;
}

/***********/
/* Helpers */
/***********/
.height-auto {
  height: auto !important;
}

.self-start {
  align-self: start !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.normalcase {
  text-transform: none !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.underline {
  text-decoration: underline !important;
}

.hidden-shadow, .hidden-shadow .v-input__slot {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.full-max-width {
  max-width: 100% !important;
}

.full-min-width {
  min-width: 100% !important;
}

.subscribe-text-field .v-input__slot{
  box-shadow: none !important;
  border-radius: 5px !important;
  white-space: nowrap !important;
  min-height: 36px !important;
  background-color: #8879f2 !important;
}

.custom-input .v-input__slot {
  border: 1px solid #E0E0E0;
  box-shadow: none !important;
  border-radius: 5px !important;
  white-space: nowrap;
  min-height: 36px !important;
}

.v-input--is-disabled.custom-input .v-input__slot {
  background-color: #E0E0E0 !important;
}

.subscribe-text-field .v-input__slot .v-text-field__slot label{
  color: #c4aeff !important;
}

.subscribe-text-field .v-input__slot .v-text-field__slot input{
  color: #ffffff !important;
}

.small-input .v-input__control {
  min-height: 36px !important;
}

.small-input .v-input__control .v-input__slot {
  border: 1px solid #E7E7E7;
  border-radius: 2px !important;
}

.quoter-input .v-input__control .v-input__slot {
  border: 1px solid #E7E7E7;
  border-radius: 2px !important;
}

.hidden-border .v-input__slot, .hidden-border .v-input__slot::before, .hidden-border .v-input__slot::after {
  border-style: none !important;
  border-color: transparent !important;
  border-width: 0px !important;
}

.hidden-border .v-text-field__slot input {
  text-align: center;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.clickable {
  cursor: pointer !important;
}

.placeholder {
  visibility: hidden !important;
}

.not-clickable .v-expansion-panel__header {
  cursor: default !important;
}

.pr-10 {
  padding-right: 96px !important;
}

.pr-20 {
  padding-right: 192px !important;
}

.pl-10 {
  padding-left: 96px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.remove-pointer-events {
  pointer-events: none !important;
}

/**********/
/* Fonts  */
/**********/
.graphik-light {
  font-family: 'Graphik-Light';
}

.graphik-medium {
  font-family: 'Graphik-Medium';
}

.graphik-bold-italic {
  font-family: 'Graphik-BoldItalic';
}

.graphik-bold {
  font-family: 'Graphik-Bold';
}

/**********/
/* Images */
/**********/

/*********/
/* Fixes */
/*********/
.hidden-image-input .v-input__slot {
  display: none !important;
}

.vc-swatches {
  width: 100% !important;
}

.v-toolbar__items .v-btn--active:before, .v-toolbar__items .v-btn:focus:before, .v-toolbar__items .v-btn:hover:before {
  background-color: transparent !important;
}

.v-toolbar__content {
  max-width: 1400px !important;
  margin: auto !important;
  padding: 0px !important;
}

.v-input.v-textarea .v-input__slot {
  min-height: inherit !important;
  max-height: inherit !important;
}

.grecaptcha-badge {
  display: none !important;
}

.readonly-button {
  pointer-events: none !important;
}

.readonly-button .v-btn__content {
  white-space: normal !important;
}

.v-expansion-panel__header__icon {
  display: none !important;
}

.v-expansion-panel__header {
  margin: 0px !important;
  padding: 0px !important;
}

.v-expansion-panel.border .v-expansion-panel__container, .v-expansion-panel.border .v-expansion-panel__container {
  border-radius: inherit !important;
}

.v-text-field.v-text-field--solo .v-input__prepend-outer, .v-text-field.v-text-field--solo .v-input__append-outer {
  margin: 0 !important;
  padding: 6px 8px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; 
  background: #4c3aa1 !important;
}

.v-text-field.v-text-field--solo .v-input__prepend-outer i, .v-text-field.v-text-field--solo .v-input__append-outer i {
  color: #ffffff !important;
}

.dark_hint .v-messages__message {
  color: #ffffff !important;
}

/***********/
/* xsOnly */
/***********/
@media screen and (max-width: 600px) {
  .readonly-button.v-btn {
    height: 160px !important;
  }
}

/***********/
/* smAndUp */
/***********/
@media screen and (min-width: 600px) {
}

/***********/
/* mdAndUp */
/***********/
@media screen and (min-width: 870px) {
}

/***********/
/* lgAndUp */
/***********/
@media screen and (min-width: 1050px) {

}

/***********/
/* xlgAndUp */
/***********/
@media screen and (min-width: 1600px) {

}

</style>
