export default {
	// Header
	header_title_quote: 'Cotizar',
	header_title_products: 'Productos',
	header_title_car: 'Auto',
	header_title_fleet: 'Flotillas',
	header_title_uber: 'Uber',
	header_title_house: 'Hogar',
	header_title_life: 'Personales',
	header_title_help: 'Ayuda',
	header_title_howitworks: 'Cómo funciona',
	header_title_faq: 'Preguntas frecuentes',
	header_title_sinister: 'Siniestros',
	header_title_profile: 'Mi perfil',
	header_title_login: 'Iniciar sesión',
	header_title_logout: 'Cerrar sesión',
	header_title_search: 'Buscar póliza',
	header_title_policies: 'Pólizas',
	header_title_companies: 'Aseguradoras',

	// Insurances
	insurance_gnp: 'GNP Seguros',
	insurance_ana: 'Ana Seguros',
	insurance_qualitas: 'Quálitas',
	insurance_axa: 'AXA',
	insurance_chubb: 'ABA | Chubb',
	'insurance_chubbhogar-1': 'ABA | Chubb Hogar',
	'insurance_chubbhogar-2': 'ABA | Chubb Hogar',
	'insurance_chubbhogar-3': 'ABA | Chubb Hogar',
	'insurance_chubbhogar-4': 'ABA | Chubb Hogar',
	insurance_primeroSeguros: 'Primero Seguros',
	insurance_hdi: 'HDI Seguros',
	insurance_mapfre: 'Mapfre',
	insurance_afirme: 'Afirme Seguros',

	// Login
	login_with: 'O ingresa con',
	login_with_facebook: 'Iniciar sesión con Facebook',
	login_with_google: 'Iniciar sesión con Google',
	login_no_account: '¿No tienes cuenta?',
	login_register: 'Regístrate',
	login_forgot_password_success: 'Te hemos enviado un correo con instrucciones para poder ingresar a tu cuenta.',
	login_reset_password_success: 'Tu contraseña ha sido actualizada, favor de volver a iniciar sesión.',
	login_recover_password: 'Recuperar contraseña',
	login_create_account: 'Registrarme',
	login_accept_terms: 'Al crear tu cuenta aceptas los ',
	login_with_register: 'O regístrate con',

	// Footer
	footer_title_gorila: 'Gorila Seguros',
	footer_title_itm: 'ITM Regiomontana Agente de Seguros',
	footer_title_id: 'Cédula de agente: M266579',
	footer_title_disclaimer: '2018 Gorila Seguros. Todos los derechos reservados.',
	footer_title_company: 'Empresa',
	footer_title_products: 'Productos',
	footer_title_help: 'Ayuda',
	footer_title_legal: 'Aviso legal',
	footer_title_about: 'Sobre nosotros',
	footer_title_companies: 'Aseguradoras',
	footer_title_howitworks: 'Cómo funciona',
	footer_title_faq: 'Preguntas frecuentes',
	footer_title_blog: 'Blog',
	footer_title_sinisters: 'Siniestros',
	footer_title_car: 'Auto',
	footer_title_fleet: 'Flotillas',
	footer_title_house: 'Hogar',
	footer_title_life: 'Personales',
	footer_title_privacy: 'Políticas de privacidad',
	footer_title_terms: 'Términos y condiciones',
	footer_title_contact: 'Contacto',
	footer_title_phone: 'Teléfono +52 (81) 5980 1244',
	footer_title_phone_2: 'Teléfono +52 (81) 5980 1252',
	footer_title_whatsapp: 'Whatsapp +52 (81) 2725 1196',
	footer_title_email: 'hola@gorilaseguros.com',
	footer_title_address: 'Torre Pabellón M Suite 36',
	footer_title_schedule_1: 'Lunes - Viernes | 9:00am - 7:00pm',
	footer_title_schedule_2: 'Sábado | 9:00am - 1:00pm',
	footer_title_schedule_placeholder: 'Lunes - ',
	footer_title_subscribe: 'Suscríbete al newsletter',
	footer_title_payment_methods: 'Métodos de pago',
	footer_title_uber: 'Uber',

	// General,
	general_understood: 'Entendido',
	general_phone_number: '81 5980 1250',
	general_accept_terms: 'Acepto los términos y condiciones de Gorila Seguros',
	general_schedule: 'L - V 8 AM - 6 PM / S 9 AM - 2 PM',
	general_send: 'Enviar',
	general_error: 'Error',
	general_accept: 'Aceptar',
	general_go_back_home: 'Ir a la página de inicio',
	general_buy: 'Comprar',
	general_buy_card: 'Comprar con tarjeta',
	general_buy_cash: 'Comprar con efectivo',
	general_view_details: 'Detalles de cobertura',
	general_success: '¡Éxito!',
	general_quote: 'Cotizar',
	general_quote_noun: 'Cotización',
	general_quote_other: 'Cotizar otro producto',
	general_no_results: 'Sin resultados',
	general_empty: '',
	general_payment_methods: 'Métodos de pago',
	general_know_more: 'Conoce más',
	general_from: 'Desde',
	general_no_data: 'Sin resultados',
	general_continue: 'Continuar',
	general_issuance: 'Emitir',
	general_recommended: 'Recomendada',
	general_edit_information: 'Editar información',
	general_to: 'a',
	general_or: 'o',
	general_send_whatsapp: 'Enviar whatsapp',
	general_call_now: 'Llamar ahora',
	general_back: 'Regresar',
	general_yes: 'Si',
	general_no: 'No',
	general_order_summary: 'Resumen de compra',
	general_forgot_password: '¿Olvidaste tu contraseña?',
	general_pay: 'Confirmar y pagar',
	general_view_policy: 'Ver póliza',
	general_pay_policy: 'Pagar',
	general_create_profile: 'Crear perfil',
	general_cancel: 'Cancelar',
	general_save: 'Guardar',
	general_not_found: 'No se encontró lo que buscabas.',
	general_goto_profile: 'Ver mi perfil',
	general_no_internet: 'Error de conexión. Verifica que cuentes con una conexión a internet, en caso de continuar con el problema, favor de contactar servicio al cliente.',
	general_continue_issuance: 'Continuar con la emisión',
	general_hello_cuco: '¡Hola! Soy Cuco',
	general_cuco_will_help: 'Yo te ayudaré a encontrar las mejores opciones de seguro.',
	general_step_1_of_2: 'Paso 1 de 2',
	general_step_2_of_2: 'Paso 2 de 2',
	general_read_more: 'Leer más',
	general_protected_data: 'Tus datos están protegidos',

	// Newsletter
	newsletter_success_message: 'Has quedado suscrito a nuestro newsletter.',

	// Fields
	fields_first_payment: 'Primer pago',
	fields_next_payment: 'Pagos subsecuentes',
	fields_total_warning: 'En esta orden se cobrará el primer pago',
	fields_total: 'Total',
	fields_policy: 'Póliza',
	fields_insurance_type: 'Tipo de seguro',
	fields_vehicle: 'Vehículo',
	fields_policy_type: 'Paquete',
	fields_inssured: 'Asegurado',
	fields_contract: 'Propietario / Contratante',
	fields_address: 'Domicilio',
	fields_token: 'Código',
	fields_password: 'Contraseña',
	fields_name: 'Nombre (s)',
	fields_last_name: 'Apellido Paterno',
	fields_second_last_name: 'Apellido Materno',
	fields_cellphone: 'Teléfono celular',
	fields_email: 'Correo electrónico',
	fields_birthday: 'Fecha de nacimiento',
	fields_zip_code: 'Código postal',
	fields_car_model: 'Modelo',
	fields_car_brand: 'Marca',
	fields_car_uuid: 'Versión',
	fields_street: 'Calle',
	fields_company: 'Aseguradora',
	fields_street_number: 'Número',
	fields_policy_number: 'Número de póliza',
	fields_house_type: 'Tipo de inmueble',
	fields_house_level: 'Número de niveles',
	fields_house_basement: 'Número de sótanos',
	fields_house_living_floor: 'Piso del departamento',
	fields_annual: 'Contado',
	fields_biannual: 'Semestral',
	fields_quaterly: 'Trimestral',
	fields_monthly: 'Mensual',
	fields_amplia: 'Amplia',
	fields_limitada: 'Limitada',
	fields_rc: 'Responsabilidad Civil',
	fields_premium: 'Premium',
	fields_optimo: 'Óptimo',
	fields_basico: 'Básico',
	fields_neighborhood: 'Colonia',
	fields_gender: 'Género',
	fields_coverage: 'Cobertura',
	fields_sum_assured: 'Suma asegurada',
	fields_deductible: 'Deducible',
	fields_validity: 'Vigencia',
	fields_coupon: 'Cupón',
	fields_payment_method: 'Forma de pago',
	fields_faq: 'Preguntas frecuentes',
	fields_doubts: '¿Dudas?',
	fields_doubts_send_message: 'Envía mensaje a nuestros asesores.',
	fields_chat: 'Chat',
	fields_last_names: 'Apellidos',
	fields_bank: 'Banco',
	fields_credit_card: 'Número de tarjeta',
	fields_expiration: 'Expiración',
	fields_cvc: 'CVC',
	fields_state: 'Estado',
	fields_street_and_number: 'Calle y número',
	fields_city: 'Ciudad',
	fields_company_name: 'Nombre de la empresa',
	fields_contact_name: 'Nombre del contacto',
	fields_uber: 'Tu auto es para servicio de:',
	fields_smoke: 'Soy fumador',
	fields_wanted_sum_assured: 'Suma asegurada deseada',
	fields_home_type: 'Tipo de vivienda',
	fields_insurance: 'Seguro',
	fields_inssured_good: 'Bien asegurado',
	fields_unit_number: 'Número de unidades',
	fields_payment_forms: 'Formas de pago',
	fields_download_and_payment: 'Descargar póliza y ficha de pago',
	fields_age: 'Edad',
	fields_car_model_quoter: 'Selecciona el año',
	fields_car_brand_quoter: 'Selecciona la marca',
	fields_car_uuid_quoter: 'Selecciona la versión',

	// Hints
	hint_email: 'Recibirás tu cotización a este correo.',
	hint_unit_number: 'No hay mínimo de unidades',
	hint_loading: 'Estamos en busca de tu cotización. Espera unos segundos.',

	// Fields options
	fields_options_technician: 'Técnico especialista',
	fields_options_pet: 'Mascótas',
	fields_options_protection: 'Protección catástrofes',
	fields_options_no_protection: 'Sin protección',
	fields_options_gender_male: 'Masculino',
	fields_options_gender_female: 'Femenino',
	fields_options_quote_type_car: 'Auto',
	fields_options_quote_type_house: 'Hogar',

	// Rules
	rules_required_field: 'Campo requerido.',
	rules_invalid_email: 'Correo electrónico inválido.',
	rules_invalid_phone: 'Teléfono inválido.',
	rules_invalid_zip_code: 'Código postal inválido.',
	rules_invalid_credit_card: 'Tarjeta inválida.',
	rules_invalid_date: 'Fecha inválida.',
	rules_invalid_cvc: 'Código de seguridad inválido.',
	rules_invalid_rfc: 'RFC inválido.',
	rules_invalid_curp: 'CURP inválido.',
	rules_invalid_plate: 'La placa debe contener 7 caracteres.',
	rules_invalid_serial: 'El número de serie debe contener 17 caracteres.',
	rules_invalid_rfc_10: 'Favor de incluir los 10 primeros dígitos de tu RFC.',
	rules_invalid_rfc_13: 'Favor de incluir los tu RFC con homoclave.',
	rules_min_8: 'Mínimo 8 caracteres',

	// Error
	error_404: 'No encontramos la página que estás buscando. Consulta nuestro centro de ayuda o vuelve a nuestra página de inicio.',
	error_sesion_expired: 'Tus credenciales han expirado. Favor de iniciar sesión nuevamente.',

	// Products
	product_car: 'Auto',
	product_house: 'Hogar ',
	product_fleet: 'Flotilla',
	product_uber: 'Uber',
	product_life: 'Personales',

	// Quoter
	quoter_insurace: 'Seguro de',
	quoter_insuraces: 'Seguros',
	quoter_car_description: 'Ingresa los datos de tu auto',
	quoter_meet: 'Nos gustaría conocerte',
	quoter_receive_quote: 'Ingresa tus datos para recibir tu',
	quoter_uber_description: 'Ingresa los datos de tu auto',
	quoter_fleet_description: 'Ingresa los datos de tu flotilla',
	quoter_life_description: 'Ingresa los datos del asegurado',
	quoter_buy_with_cash_we_got_your_data: '¡Ya tenemos tus datos!',
	quoter_buy_with_cash_we_contact_you: 'Nos pondremos en contacto contigo.',
	quoter_chubb_offline_sorry_inconveniences: 'Disculpe las molestias',
	quoter_chubb_offline_schedule: 'De 9PM - 11:59PM la agencia Aba | Chubb no genera emisiones.',
	quoter_house_description: 'Ingresa los datos de tu hogar',
	quoter_buy_with_cash_axa: 'Asegura un mejor precio al pagar con Tarjeta de Crédito o Débito.',

	// Quote
	quote_result_annual: 'Anual (Pago único)',
	quote_result_first_payment: 'Primer pago de ',

	// Issuance
	issuance_title_1: '¡Es hora de',
	issuance_title_2: 'contratar tu seguro !',
	issuance_description: 'Compártenos los siguientes datos para poder generar tu seguro. Asegúrate de que la información sea correcta.',
	issuance_contact: 'Acerca del asegurado',
	issuance_driver_is_same_as_contract: 'El beneficiario y el contratante son la misma persona.',
	fields_issuance_driver_first_name: 'Nombre',
	fields_issuance_motor: 'Número del Motor',
	fields_issuance_driver_middle_name: 'Segundo Nombre',
	fields_issuance_driver_last_name: 'Apellido Paterno',
	fields_issuance_driver_second_last_name: 'Apellido Materno',
	fields_issuance_driver_gender: 'Género',
	fields_issuance_driver_birthday: 'Fecha de nacimiento',
	fields_issuance_driver_marital_status: 'Estado Civil',
	fields_issuance_driver_rfc: 'RFC',
	fields_issuance_driver_zip_code: 'Código Postal',
	fields_issuance_driver_neighborhood: 'Colonia',
	fields_issuance_driver_street: 'Calle',
	fields_issuance_driver_street_number_int: 'Número Interior',
	fields_issuance_driver_street_number_ext: 'Número Exterior',
	fields_issuance_driver_phone: 'Teléfono celular',
	fields_issuance_driver_serial: 'Número de serie',
	fields_issuance_driver_plate_number: 'Placas',
	fields_issuance_driver_plate_number_state: 'Estado de las placas',
	fields_issuance_options_single: 'Soltero',
	fields_issuance_options_married: 'Casado',
	fields_issuance_options_divorced: 'Divorciado',
	fields_issuance_options_widow: 'Viudo',
	fields_issuance_options_man: 'Masculino',
	fields_issuance_options_woman: 'Femenino',
	fields_issuance_driver_identification_type: 'Tipo de identificación',
	fields_issuance_driver_identification_number: 'Número de identificación',
	fields_issuance_driver_curp: 'CURP',
	fields_issuance_driver_military_premier: 'Cartilla Militar',
	fields_issuance_driver_professional_certificate: 'Cédula Profesional',
	fields_issuance_driver_id: 'IFE',
	fields_issuance_driver_other: 'Otro',
	fields_issuance_driver_passport: 'Pasaporte',

	// Landing
	landing_title: 'Cotiza, compara y contrata tu seguro',
	landing_title_seconds: 'en segundos',
	landing_description: '¿Qué te gustaría',
	landing_description_protect: 'proteger?',
	
	// Car
	landing_car_title_1: 'Cotiza, compara y contrata el seguro de',
	landing_car_title_2: 'tu auto',
	landing_car_description: '¡Es rápido y muy sencillo!',
	landing_car_quote: 'Cotiza tu auto con Gorila Seguros',
	landing_company_gnp: 'con GNP',
	landing_car_description_gnp: 'Con opciones de pago de 3, 6 y 12 meses sin intereses',

	// Fleet
	landing_fleet_quote: 'Cotiza tu flotilla con Gorila Seguros',
	landing_fleet_title_1: 'Cotiza, compara y contrata el seguro de',
	landing_fleet_title_2: 'tu flotilla',
	landing_fleet_description: '¡Es rápido y muy sencillo!',

	// Uber
	landing_uber_title_1: 'Cotiza, compara y contrata el seguro para',
	landing_uber_title_2: 'uber u otra aplicación',
	landing_uber_description: '¡Es rápido y muy sencillo!',

	// House
	landing_house_title_1: 'Cotiza, compara y contrata el seguro de',
	landing_house_title_2: 'tu hogar',
	landing_house_quote: 'Cotiza tu hogar con Gorila Seguros',
	landing_house_description: '¡Es rápido y muy sencillo!',

	// Life
	landing_life_quote: 'Cotiza tu seguro de gastos médicos con Gorila Seguros',
	landing_life_description: '¡Es rápido y muy sencillo!',
	landing_life_title_1: 'Cotiza, compara y contrata un seguro de',
	landing_life_title_2: 'Gastos médicos mayores y de vida',
	landing_life_cuco_will_help: '¿Qué tipo de seguro estás buscando?',

	// Content
	content_reviews_title: 'Nuestros clientes confían en nosotros',
	content_products_title: 'Nos gustaría ser parte de tu vida',
	content_products_description: 'Conoce nuestros diferentes productos, asegúrate y vive tranquiiiiiiiilo con Gorila Seguros.',
	content_products_insurance: 'Seguros',
	content_products_my: 'Mi',
	content_payment_methods_description: 'Para tu comodidad, contamos con diferentes formas de pago.',
	content_how_it_works_title: '¿Cómo funciona?',
	content_how_it_works_step_1: 'Cotiza',
	content_how_it_works_step_1_description: 'LLena tus datos y cotiza el seguro que necesitas en segundos y sin costo.',
	content_how_it_works_step_2: 'Compara',
	content_how_it_works_step_2_description: 'Compara entre las mejores aseguradoras.',
	content_how_it_works_step_3: 'Contrata',
	content_how_it_works_step_3_description: 'Elige la mejor opción y contrata tu seguro de manera rápida y sencilla.',
	content_how_it_works_step_4: 'Ingresa tus datos',
	content_how_it_works_step_4_description: 'Con tus datos podremos evaluar y seleccionar las mejores opciones para ti.',
	content_how_it_works_step_5: 'Un asesor te contactará',
	content_how_it_works_step_5_description: 'Un asesor te contactará para brindarte información personalizada.',
	content_benefits_step_1: 'Te ofrecemos la mejor calidad',
	content_benefits_step_1_description: 'Juntamos a las mejores aseguradoras para que en cuestión de segundos puedas cotizar y contratar tu seguro.',
	content_most_quality_description: 'Para ofrecerte la mejor calidad, trabajamos con las mejores aseguradoras del pais.',
	content_benefits_step_2: 'Cuco te ofrece los mejores beneficios que existen en el mercado',
	content_benefits_step_2_description_1: 'Descuentos y promociones exclusivas.',
	content_benefits_step_2_description_2: 'Te cotizamos en segundos para que puedas contratar en minutos.',
	content_benefits_step_2_description_3: 'Fácil y seguro.',
	content_benefits_step_2_description_4: 'Las mejores aseguradoras en un mismo lugar.',
	content_benefits_step_2_description_5: 'Gran variedad de productos.',
	content_benefits_step_2_description_6: '¿Dudas? No te preocupes, uno de nuestros asesores te ayuda.',
	content_most_quoted_cars_title: 'Los autos más cotizados al mejor precio',
	content_most_quoted_cars_description: '(Las cotizaciones son en cobertura amplia de contado)',
	content_coverages_cars_title: 'Contamos con diferentes coberturas para tu auto',
	content_contact_for_quoting_description: 'En breve un asesor te estará contactando para iniciar tu proceso de cotización.',
	content_apps_companies_title: 'Las aplicaciones más cotizadas',
	content_apps_companies_description: 'Ofrecemos seguros para conductores de diferentes aplicaciones.',
	content_house_price_description_1: 'Vive tranquilo asegurando tu hogar desde',
	content_house_price_description_2: 'pesos al año.',
	content_coverages_house_title: 'Con Gorila proteges lo que más importa',
	content_coverages_house_step_1: 'Tu hogar',
	content_coverages_house_step_1_description: 'Protege tu hogar de desastres como incendios, inundaciones, terremotos.',
	content_coverages_house_step_2: 'Tus pertenencias',
	content_coverages_house_step_2_description: 'Equipo electrónico, muebles y objetos personales contra robo y daños.',
	content_coverages_house_step_3: 'Tu familia',
	content_coverages_house_step_3_description: 'Protege y asegura a tu familia y a tu mascota de cualquier daño y vive con más tranquilidad.',
	content_coverages_house_step_4: 'Más beneficios',
	content_coverages_house_step_4_description: 'Asistencia de Emergencia (Plomería, cerrajería y electricidad), Asistencia Tecnológica.',
	content_house_benefits_description_1: '21 coberturas en una misma póliza',
	content_house_benefits_description_2: 'Protege tu hogar, tus cosas y a tu familia.',
	content_house_benefits_description_3: 'Asistencia de Emergencia (Plomería, Cerrajería y Electricidad)',
	content_house_benefits_description_4: 'Asistencia Tecnológica.',
	content_house_benefits_description_5: 'Sin recargo por pago fraccionado.',
	content_house_benefits_title: 'Beneficios de asegurar tu hogar con',
	content_life_benefits_title: 'Beneficios de tener tu seguro de vida con',
	content_life_benefits_title_benefits: 'Gorila Seguros',
	content_life_benefits_description_1: 'Seguridad económica para tu familia',
	content_life_benefits_description_2: 'Protección en caso de invalidez o enfermedad',
	content_life_benefits_description_3: 'Deducible de impuestos',
	content_life_benefits_description_4: 'Un paquete personalizado que se adapta a tus necesidades',
	content_house_benefits_title_benefits: 'Gorila Seguros',
	content_gnp_benefits_title: '¿Qué beneficios ofrece GNP?',
	content_benefits_gnp_description_1: 'Con Valor Convenido garantiza la Suma Asegurada de tu vehículo durante toda la vigencia.',
	content_benefits_gnp_description_2: 'Puedes elegir la cobertura de Auto Sustituto para Pérdida Total o Parcial.',
	content_benefits_gnp_description_3: 'Te brinda protección más allá de las fronteras al viajar con tu auto por Estados Unidos y Canadá.',
	content_benefits_gnp_description_4: 'Paga tu seguro a 3 o 6 meses sin intereses.',
	content_benefits_gnp_description_5: 'Agregamos el Seguro Obligatorio de Responsabilidad Civil Vehicular, sin costo adicional, a la prima del seguro.',

	// Coverages
	coverages_materials: 'Daños materiales.',
	coverages_steal: 'Robo total.',
	coverages_rc_usa: 'Responsabilidad civil en México y E.U.A.',
	coverages_rc_mexico: 'Responsabilidad civil en México.',
	coverages_medical: 'Gastos médicos ocupantes.',
	coverages_assistance: 'Asistencia vial y legal.',
	coverages_others: 'Otros beneficios.',

	// Quote Results
	quote_results_quoting: 'Estás cotizando',
	quote_results_most_bought: 'El más comprado',
	quote_results_cheapest: 'El más barato',
	quote_results_to_quote: 'Para cotizar, llama gratis',

	// FAQ
	faq_question_1: '¿Tipo de Coberturas?',
	faq_answer_1_1: '• Amplia: Protege tu auto contra daños, robo y responsabilidad civil.',
	faq_answer_1_2: '• Limitada: Protege tu auto contra robo y responsabilidad civil.',
	faq_answer_1_3: '• Responsabilidad Civil: Responsabilidad civil.',
	faq_answer_1_4: 'Todas incluyen Gatos Médicos a Ocupantes, Asistencia Vial y Legal, entre otras coberturas. ',
	faq_question_2: '¿Que es RC?',
	faq_answer_2: 'La cobertura de responsabilidad civil o contra terceros, protege los daños a terceros durante accidentes. Ya sea otros automóviles, mobiliario urbano o a personas.',
	faq_question_3: '¿Formas de pago?',
	faq_answer_3_1: '• Anual (La más económica)',
	faq_answer_3_2: '• Semestral',
	faq_answer_3_3: '• Trimestral',
	faq_answer_3_4: '• Mensual',
	faq_answer_3_5: 'Paga con tarjeta de crédito, débito o efectivo.',
	faq_question_4: '¿Cuantas coberturas incluyen los paquetes del Seguro de Hogar de Chubb?',
	faq_answer_4: 'Son 21 coberturas diseñadas para proteger tu hogar, contenidos y a tu familia.',
	faq_question_5: '¿Contra que protejo mi hogar?',
	faq_answer_5: 'Contra incendios, inundaciones, terremotos, heladas, robo, entre otros riesgos. Tanto el inmueble como sus contenidos.',
	faq_question_6: '¿Qué protege la cobertura de responsabilidad civil en la póliza de hogar?',
	faq_answer_6: 'Cubre los daños que el asegurado y/o sus dependientes económicos ocasionen a terceros dentro y fuera del hogar.',
	// Validate Car
	validate_car_you_chose: 'El modelo que elegiste es:',
	validate_car_insurance: 'Lo tiene en su sistema como:',
	validate_car_yes_no: '¿Sí es el modelo de tu auto?',
	validate_car_no: 'Tu vehículo tuvo problemas.',
	validate_car_chose_no: 'Puedes intentar con otra aseguradora o esperar a que nos contactemos contigo.',

	// Checkout
	checkout_title: '¡Ya casi acabas!',
	checkout_description: 'Compártenos tu método de pago y dirección de facturación. No olvides checar que la información de tu auto y póliza sea la correcta.',
	checkout_payment: 'Forma de pago',
	checkout_address: 'Dirección de facturación',

	// Thank you
	thankyou_title: '¡Ya estás asegurado!',
	thankyou_description: 'Recibirás un correo al e-mail registrado con tu póliza.',
	thankyou_cash_description_1: 'Asegúrate de pagar tu póliza lo antes posible para evitar que se cancele y estés siempre protegido.',
	thankyou_cash_description_2: 'Cualquier duda, ¡con gusto te apoyamos!',
	thankyou_cash_phone: '811 917 4197',

	// Policy
	policy_download: 'Descarga tu póliza',
	policy_document: 'Documento',
	policy_insured_data: 'Datos del asegurado y/o contratante',

	// Profile
	profile_title: 'Acerca de ti',
	profile_policies: 'Pólizas',
	profile_edit: 'Editar información',
	profile_policies_quote: 'Comienza a cotizar en segundos.',

	// Uber
	uber_uber: 'Uber',
	uber_multi_platform: 'Multiplataforma (Uber, Didi, Cabify, etc.)',
	uber_title_1: 'Seguros para',
	uber_title_2: 'y otras aplicaciones',

	// Life
	life_want_to_quote: 'Deseo cotizar seguro de',
	life_life: 'De Vida',
	life_medical: 'Gastos médicos',
	life_im_insured: 'Actualmente cuento con seguro.',
	life_title_1: 'Seguros personales',
	life_title_2: 'gastos médicos mayores y vida.',

	// Fleet
	fleet_title_1: 'Protege la',
	fleet_title_2: 'de tu empresa con',

	// House
	house_title_1: 'Asegura el lugar donde vives, todo lo que hay en él y a los que viven ahí.',
	house_title_2: 'Protege tu',
	house_title_3: 'en minutos.',
	house_heritage: 'Patrimonio',

	// How it works
	how_it_works_title: '¿Cómo funciona?',
	how_it_works_description: 'Seguro te parece muy fácil. Creamos nuestra página para que puedas comprar un seguro en minutos. Te explicamos cómo:',
	how_it_works_step_1_title: '1. ¿Qué seguro necesitas?',
	how_it_works_step_1_description: 'Protegemos como gorila tu auto, casa y vida. Tú escoges. Juntamos a las mejores aseguradoras de México en nuestra plataforma, para que puedas compararlas. ¡Así ahorras tiempo y dinero!',
	how_it_works_step_2_title: '2. ¿No sabes por dónde empezar?',
	how_it_works_step_2_description: 'No importa que no sepas de seguros. Anímate a cotizar, nosotros te guiamos y explicamos cada paso. Además, tenemos nuestro chat en vivo, por si tienes alguna duda, te ayudamos a contestarla.',
	how_it_works_step_3_title: '3. Llena tus datos.',
	how_it_works_step_3_description: 'Contesta unas simples pregutnas sobre ti y de lo que quieres que te aseguremos. Mientras vayas contestando, nosotros te vamos explicando las coberturas y algún término que no conozcas.',
	how_it_works_step_4_title: '4. Cotiza y compara.',
	how_it_works_step_4_description: 'Te mostramos las mejores opciones que ofrece cada aseguradora y con sus precios directos. Para que puedas comparar y ahorrar. Si quieres más coberturas o personalizar, cuéntanos un poquito más.',
	how_it_works_step_5_title: '5. Compra tu seguro.',
	how_it_works_step_5_description: 'Escoge el seguro mejor para ti, te pediremos unos datos sencillos para poder emitir tu póliza. Después, te vinculamos con la página de la aseguradora para que hagas el pago directo con ellos.',
	how_it_works_step_6_title: '6. Ya estás asegurado.',
	how_it_works_step_6_description: 'Enviamos tu póliza digital a tu correo y la guardamos en tu perfil Gorila. Así tu seguro te acompaña a todos lados. Te recomendamos que la leas, para asegurarte que tu información este bien.',

	// Sinisters
	sinisters_title: '¿Necesitas reportar un siniestro?',
	sinisters_description: 'Tranquilo, aquí te compartimos a dónde comunicarte. Te van a pedir el número de la póliza, no te preocupes, recuerda que te lo mandamos a tu mail.',
	sinisters_app: '¡También puedes reportar cualquier tipo de siniestro desde tu app!',
	
	// Companies
	companies_title: 'Seguro las conoces',
	companies_description: 'Las mejores aseguradoras de México están aquí, con los modernos como tú.',
	companies_afirme_description: 'Respaldados por Afirme Grupo Financiero, tienen más de 17 años de experiencia en la industria de los seguros. Están posicionados como una de las empresas aseguradoras más confiables, con fuerte presencia en seguros de auto y daños.',
	companies_ana_description: 'Compañía especializada en seguros para automóvil con más de 20 años de experiencia en la industria. Destacada por su eficiencia y tiempo de respuesta, gracias a las más de 85 oficinas de servicio que tienen a nivel nacional.',
	companies_axa_description: 'Empresa líder a nivel mundial en la industria de seguros. Cuenta con presencia en más de 64 países y más de 103 millones de clientes en todo el mundo. En México, AXA tiene más de 10 años de experiencia, con más de 5 millones de clientes.',
	companies_chubb_description: 'ABA Seguros se ha consolidado por su eficiencia en el servicio y vanguardia en coberturas. Es parte de Chubb, empresa líder en la industria de seguros a nivel mundial, con presencia en más de 54 países. Cuenta con una alta variedad de productos y coberturas, con fuerte presencia en Seguros de Auto y Hogar.',
	companies_gnp_description: 'Grupo Nacional Provincial es una de las aseguradoras más grandes de México, con 117 años de experiencia. Respaldada por su solvencia, profesionalismo y confianza.',
	companies_hdi_description: 'Pertenece a Grupo Talanx, una de las aseguradoras más grandes de Europa con base en Alemania. Hace más de 7 años llegan a México y después de comprar diferentes aseguradoras en el país, crean HDI Seguros.',
	companies_mapfre_description: 'Empresa multinacional con gran presencia en la industria de seguros con 80 años de experiencia. MAPFRE ofrece un servicio integral con centros especializados de atención de siniestros, amplia red médica y de talleres a nivel nacional.',
	companies_primeroSeguros_description: 'Se destaca por desarrollar soluciones innovadoras para sus clientes, con gran variedad en productos y coberturas. Mucha experiencia y crecimiento en el ramo automotriz en los últimos años.',
	companies_qualitas_description: 'Compañía 100% mexicana, con más de 25 años de experiencia en la industria de seguros. Desde hace 12 años es la compañía número 1 en México en Seguros de Autos. Se destaca por ser líder y especialista en el ramo automotriz.',

	// FAQ
	faq_title: 'Preguntas Frecuentes',
	faq_description: 'Los seguros pueden ser confusos... No te preocupes, aquí te resolvemos todas tus dudas.',
	faq_sections: 'Secciones',
	faq_title_about: 'Sobre Gorila',
	faq_title_policies: 'Tu Póliza y Pagos',
	faq_title_car: 'Seguro de Auto',
	faq_title_house: 'Seguro de Hogar',
	faq_about_question_1: '¿Por qué necesito un seguro?',
	faq_about_answer_1: 'El mundo está lleno de sorpresas y nunca sabes lo que te puede pasar. Para eso son los seguros, protegen todo eso por lo que has trabajado y a los que quieres ante algún evento desafortunado.\n\nAdemás, las aseguradoras tienen servicios extras que son gratuitos, ¡sólo por tener un seguro con ellos! Como servicio de grúa o asistencia tecnica en el hogar.',
	faq_about_question_2: '¿Qué es un siniestro?',
	faq_about_answer_2: 'Seguro esta palabra la has escuchado cuando te hablan las aseguradoras. Un siniestro es cuando te pasa un incidente y necesitas aplicar tu seguro. Existen muchos tipos de siniestros, puede ser un choque, robo, un incendio entre otros.',
	faq_about_question_3: '¿Qué tipo de seguros venden?',
	faq_about_answer_3: 'Nos especializamos en seguros para Autos, Hogar, Gastos Médicos Mayores y Vida.',
	faq_about_question_4: '¿Gorila Seguros es una aseguradora?',
	faq_about_answer_4: 'No, somos un agente digital que trabaja con las mejores aseguradoras y te facilita el proceso de contratación.',
	faq_about_question_5: 'Entonces, ¿con ustedes me saldría más caro?',
	faq_about_answer_5: '¡Claro que no! Las aseguradoras nos dan una comisión por venta y manejo de tu seguro. ¡A ti no te costará ni un centavo más! Además, tenemos acceso a grandes descuentos y promociones que ponemos a tu alcance.',
	faq_about_question_6: '¿Gorila Seguros está certificado?',
	faq_about_answer_6: 'Por supuesto, contamos con la autorización oficial de la CNSF (Comisión Nacional de Seguros y Fianzas). Sin ella no podríamos ofrecerte nuestros servicios. Nuestra razón social es ITM Regiomontana Agente de Seguros S.A. de C.V. y nuestra cédula de agente es M266579.',
	faq_policies_question_1: 'La póliza no llegó a mi correo. ¿Qué hago?',
	faq_policies_answer_1: '¡Ups, una disculpa! La póliza la puedes encontrar en tu perfil dentro de gorilaseguros.com. Si lo prefieres, te la podemos enviar, sólo contáctanos en nuestro chat, al 52 (81) 5980 1244 o envíanos un correo a hola@gorilaseguros.com.',
	faq_policies_question_2: '¿Puedo cancelar mi póliza?',
	faq_policies_answer_2: 'Puedes cancelar tu seguro cuando gustes. Para hacer una cancelación tienes que hacer el trámite con la aseguradora que emitió tu póliza. De igual manera, nosotros te podemos asesorar en el proceso.',
	faq_car_question_1: '¿Qué pasa si choco fuera de mi ciudad?',
	faq_car_answer_1: 'Tu seguro tiene alcance a nivel nacional, estas protegido en todo México.',
	faq_car_question_2: '¿Estoy asegurado aunque esté manejando en Estados Unidos?',
	faq_car_answer_2: 'Puedes contratar la cobertura de Responsabilidad Civil en Estados Unidos, la mayoría de las aseguradoras ya la ofrecen en la cobertura amplia.',
	faq_house_question_1: '¿Qué tipo de accidentes cubre mi seguro comúnmente?',
	faq_house_answer_1: 'Algunos de los incidentes que protege el seguro de hogar son:\n\n• Daños a tu casa y lo que tienes dentro ella, por si se rompe una ventana o se te daña algún equipo electrónico.\n\n• Desastres naturales e incendios.\n\n• Accidentes que pueda tener alguien dentro de tu casa; ya sea tu hijo, la vecina o la señora de limpieza.\n\n• Robos totales o parciales de contenidos\n\n• Porque seguro lo vas a necesitar, también tenemos seguros con asistencia técnica; por si algún día necesitas un cerrajero, un plomero o electricista.  \n\n• Responsabilidad Civil Familiar: Cubre los daños que el asegurado y sus dependientes económicos causen a terceros dentro y fuera del hogar.\n\n• Cobertura de Gastos Extras, en caso de que incurres en gastos extras a consecuencia de un siniestro. Por ejemplo, pago de noches de hotel\n\n• Para tus perros y gatos porque sabemos que son parte de la familia.\n\n• Gastos por remoción de escombros.\n\n¡OJO! Todas estas coberturas aplican solo si sucede un incidente inesperado.',

	// About
	about_title: 'Nosotros',
	about_description: 'En Gorila buscamos que cambies tu perspectiva sobre el mundo de las aseguradoras y que siempre estés protegido, ¿por qué?',
	about_description_bold: 'Queremos un México más seguro.',
	about_products_title: 'El número de personas aseguradas en México es muy bajo.',
	about_insurance_car_title: 'Seguro de Autos',
	about_insurance_car_description: 'Solo 4 de cada 10 autos están asegurados.',
	about_insurance_house_title: 'Seguro de Hogar',
	about_insurance_house_description: 'Que protege a los que viven ahí y sus pertenencias.',
	about_insurance_life_title: 'Seguro de Gastos Médicos Mayores',
	about_insurance_life_description: 'Protégete ante cualquier problema de salud o accidente',
	about_benefits_title: 'Con Gorila Seguros, obtienes',
	about_benefits_title_benefits: 'beneficios exclusivos',
	about_benefits_1_description: 'La mayoría de las veces, sucesos inesperados impactan nuestras vidas y es mejor estar prevenidos.',
	about_benefits_2_description: 'No tendrás que utilizar tus ahorros a la hora de un imprevisto; ¡tu seguro lo cubre!',
	about_benefits_3_description: 'Además de las coberturas contratadas, tendrás acceso a servicios extras que son gratuitos por parte de la aseguradora.',
	about_insurances_title: 'Seguro te queremos',
	about_insurances_description_1: 'Por eso, creamos Gorila Seguros, una plataforma con la intención de revolucionar el mundo de los seguros en México. La idea es que la compra de un seguro sea más fácil, rápida y accesible.',
	about_insurances_description_2: 'Además de esto, queremos concientizar a todos los mexicanos y hacerles entender que un seguro no es gasto, sino una inversión en la protección personal, de tus bienes y de los que más quieres; algo que agradecerás en el futuro.',
	about_insurances_description_3: 'Nos afiliamos con las mejores aseguradoras de todo México para que puedas cotizar, comparar y comprar el seguro que más se acomode a lo que necesitas. ¡Todo lo haces en minutos y en un mismo lugar! Lo único que necesitas es tu dispositivo, conexión a internet y contestar unas simples preguntas. ¿Qué esperas para asegurarte?',
	about_cuco_title: '¡Conoce a CUCO!',
	about_cuco_hobbies: 'Hobbies: Proteger a los que lo rodean y cuidar el medio ambiente.',
	about_cuco_location: 'Ubicación: Todo México a Nivel Nacional',

	// Confirm email
	confirmemail_title: '¡Felicidades, ya eres parte de la manada!',
	confirmemail_description: 'Tu correo ha sido confirmado.'
}
