import Vue from 'vue'

export default {
  getBanks (bank, callback, errorHandler) {
    return Vue.http.get('public/user/bank/' + bank).then(callback, errorHandler)
  },
  checkoutGetPolicy (policy, callback, errorHandler) {
    return Vue.http.get('public/checkout/product/' + policy).then(callback, errorHandler)
  },
  payPolicy (policy, payload, callback, errorHandler) {
    return Vue.http.patch('public/checkout/' + policy, payload).then(callback, errorHandler)
  },
  createDocument (policy, callback, errorHandler) {
    return Vue.http.post('public/checkout/document/' + policy).then(callback, errorHandler)
  },
}
