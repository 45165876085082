import Vue from 'vue'

export default {
  getPolicy (policy, callback, errorHandler) {
    return Vue.http.get('public/policy/' + policy).then(callback, errorHandler)
  },
  searchPolicy (search, callback, errorHandler) {
    return Vue.http.get('public/policy/search/' + search.last_name + '/' + search.policy + '/' + search.insurance).then(callback, errorHandler)
  },
  getPolicies (callback, errorHandler) {
    return Vue.http.get('user/policy/list').then(callback, errorHandler)
  }
}
