import Vue from 'vue'

export default {
  getNeighborhoods (zipcode, callback, errorHandler) {
    return Vue.http.get('public/issuance/location/' + zipcode).then(callback, errorHandler)
  },
  getIssuanceFields (policy, contact, callback, errorHandler) {
    return Vue.http.get('public/issuance/view/layout/' + policy + '/' + contact).then(callback, errorHandler)
  },
  getIssuanceAnswers (policy, callback, errorHandler) {
    return Vue.http.get('public/issuance/view/answer/' + policy).then(callback, errorHandler)
  },
  createIssuance (payload, callback, errorHandler) {
    return Vue.http.post('public/issuance/lead', payload).then(callback, errorHandler)
  },
  saveIssuance (field, answer, callback, errorHandler) {
    return Vue.http.patch('public/issuance/answer/' + field, answer).then(callback, errorHandler)
  },
  finishIssuance (policy, callback, errorHandler) {
    return Vue.http.patch('public/issuance/' + policy).then(callback, errorHandler)
  },
  getIssuanceProduct (product, quote, frequency, callback, errorHandler) {
    return Vue.http.get('public/issuance/product/' + product + '/' + quote + '/' + frequency).then(callback, errorHandler)
  },
  getCarValidate (quote, product, callback, errorHandler) {
    return Vue.http.get('public/issuance/verify/car/' + quote + '/' + product).then(callback, errorHandler)
  },
  validateCar (quote, product, payload, callback, errorHandler) {
    return Vue.http.patch('public/issuance/verify/car/' + quote + '/' + product, payload).then(callback, errorHandler)
  }
}
