import 'normalize.css'
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import VueResource from 'vue-resource'
import Api from './api'
import GlobalMixins from './mixins/GlobalMixins'
import VueMoment from 'vue-moment'
import './ml'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [new Sentry.Integrations.Vue({
      Vue,
      attachProps: true
    })]
  })
}

// Moment language
const moment = require('moment')
require('moment/locale/es')

Vue.use(Api)
Vue.use(VueResource)

Vue.use(VueMoment, {
  moment
})

// HTTP Requests Interceptors
Vue.http.options.root = process.env.VUE_APP_API_URL
Vue.http.interceptors.push((request, next) => {
  request.headers.set('Content-Type', 'application/json')
  if (!request.headers.map.Authorization) {
    request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
  }

  if (!request.headers.map['Content-Type']) {
    request.headers.set('Content-Type', 'application/json')
  }

  request.headers.set('X-Authorization', localStorage.recaptcha_token)
  next()
})

Vue.mixin(GlobalMixins)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
