<script>
  import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        g_isLoading: false,
        two_digits_ladas: [81,55,33],
        three_digits_ladas: [353,283,866,677,429,835,951,659,918,228,753,417,718,734,762,988,736,325,744,741,378,387,953,274,775,279,249,776,275,743,924,623,241,772,732,471,434,288,272,418,832,698,355,687,784,786,774,643,482,454,694,377,923,633,444,441,892,472,449,426,962,668,317,324,764,756,386,243,733,423,778,236,376,419,997,984,765,647,246,757,462,738,981,998,868,278,224,716,725,724,748,833,493,919,637,672,375,226,276,297,481,961,477,455,971,312,862,826,921,372,751,327,726,323,496,374,271,739,768,731,442,448,597,222,594,452,767,993,894,715,435,284,697,831,676,425,754,413,453,599,447,467,346,313,936,351,834,797,348,451,469,897,934,635,422,634,473,461,966,232,358,235,487,844,354,938,326,727,349,388,371,499,712,789,717,735,244,437,415,391,742,245,845,769,489,914,345,233,321,745,316,238,649,991,642,983,645,644,615,958,662,646,475,954,781,315,922,656,464,438,996,761,311,287,613,636,433,658,992,673,746,641,872,474,485,273,229,773,498,329,624,846,385,486,964,841,829,994,828,478,871,722,986,749,759,495,432,616,314,322,625,873,982,674,436,411,476,431,458,443,459,465,937,933,357,916,294,999,629,488,785,456,631,825,675,618,968,877,648,891,963,639,341,494,652,791,755,483,424,319,723,737,782,381,328,867,686,412,842,393,967,421,696,667,394,468,626,593,987,695,965,869,282,913,671,777,352,384,466,373,457,714,612,588,763,383,985,595,296,747,231,614,823,427,861,653,665,664,343,972,492,995,428,669,445,285,359,463,389,711,356,771,622,836,917,969,824,414,248,821,766,247,227,344,632,932,721,392,878,719,621,638,223,225,899,728,864,779,281,596,661,628,382,395,347,591,592,627,758,783,237,713,342,651],
        g_dialog: {
          title: '',
          message: '',
          show: false
        },
        g_scrollOptions: {
          duration: 300,
          offset: -80,
          easing: 'easeInOutCubic'
        },
        g_required_rules: [
          v => (!!v || v === 0) || this.$ml.get('rules_required_field')
        ],
        g_email_rules: [
          v => (!!v || v === 0) || this.$ml.get('rules_required_field'),
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$ml.get('rules_invalid_email')
        ],
        g_password_rules: [
          v => !!v || this.$ml.get('rules_required_field'),
          v => v.length > 7 || this.$ml.get('rules_min_8'),
        ],
        g_phone_rules: [
          v => (!!v || v === 0) || this.$ml.get('rules_required_field'),
          v => (!! v &&
            (
              this.two_digits_ladas.indexOf(parseInt(v.substring(0,2))) >= 0 ||
              this.three_digits_ladas.indexOf(parseInt(v.substring(0,3))) >= 0
            )
          ) || this.$ml.get('rules_invalid_phone'),
          v => (!!v && v.length > 9) || this.$ml.get('rules_invalid_phone')
        ],
        g_zip_code_rules: [
          v => (!!v || v === 0) || this.$ml.get('rules_required_field'),
          v => (!!v && v.length > 4) || this.$ml.get('rules_invalid_zip_code')
        ],
        g_credit_card_rules: [
          v => !!v || this.$ml.get('rules_required_field'),
          v => (!!v && window.Conekta.card.validateNumber(v)) || this.$ml.get('rules_invalid_credit_card')
        ],
        g_expiration_month_rules: [
          v => !!v || this.$ml.get('rules_required_field'),
          v => (!!v && parseInt(v) < 13 && parseInt(v) > 0) || this.$ml.get('rules_invalid_date')
        ],
        g_cvc_rules: [
          v => !!v || this.$ml.get('rules_required_field'),
          v => (!!v && window.Conekta.card.validateCVC(v)) || this.$ml.get('rules_invalid_cvc')
        ],
        g_neighborhood_rules: [
          v => !!v || this.$ml.get('rules_required_field'),
          v => !(v === -1) || this.$ml.get('general_no_results'),
        ]
      }
    },
    computed: {
      ...mapGetters({
        g_auth: 'auth',
        g_stateUser: 'user',
        g_language: 'language'
      }),
      g_user () {
        const newUser = {...this.g_stateUser}
        return newUser
      }
    },
    filters: {
      price (val) {
        if (!val && val !== 0) return

        if (typeof val === 'string') return val

        val = val.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        return '$' + val + ' MXN'
      }
    },
    components: {
      'message-dialog': () => import(/* webpackPrefetch: true */ '../components/MessageDialog'),
      'loading-dialog': () => import(/* webpackPrefetch: true */ '../components/LoadingDialog'),
      'dynamic-field': () => import(/* webpackPrefetch: true */ '../components/DynamicField')
    },
    methods: {
      $auth (auth, redirect) {
        this.g_isLoading = true

        this.$api.auth(
          auth,
          response => {
            this.$store.dispatch('authenticate', {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token
            })
            this.$authGetUser(redirect)
          },
          response => this.$errorHandling(response).then(() => {}, () => {})
        )
      },
      $recaptcha (action) {
        if (window.grecaptcha) {
          return new Promise((resolve) => {
            window.grecaptcha.ready(() => {
              window.grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_TOKEN, {action: action}).then(token => {
                return resolve(token)
              })
            })
          })
        } else {
          setTimeout(() => {
            this.$recaptcha(action)
          }, 300)
        }
      },
      $validateRecaptcha (resolve, reject) {
        this.g_isLoading = true
        this.$recaptcha('public').then(token => {
          this.$api.recaptcha(
            { recaptcha_token: token },
            response => {
              localStorage.setItem('recaptcha_token', response.data.data.token)
              this.g_isLoading = false
              if (resolve) {
                return resolve(true)
              }
            },
            response => {
              if (resolve) {
                return reject(Error('Invalid recaptcha token.'))
              }

              this.g_dialog = {
                title: this.$ml.get('general_error'),
                message: (response.body && response.body.description) || (response.body && response.body.error_description),
                show: true
              }

              this.g_isLoading = false
            }
          )
        })
      },
      $errorHandling (response) {
        if (response.status === 401) {
          return new Promise((resolve, reject) => {
            this.$api.authRefresh(
              response => {
                this.$store.dispatch('authenticate', {
                  access_token: response.data.access_token,
                  refresh_token: response.data.refresh_token
                })
                this.$authGetUser(false, resolve)
              },
              () => {
                reject(Error('Invalid refresh token.'))
                this.$authLogout()
                this.g_dialog = {
                  title: this.$ml.get('general_error'),
                  message: this.$ml.get('error_sesion_expired'),
                  show: true
                }
                this.g_isLoading = false
              }
            )
          })
        } else if (response.status === 403) {
          return new Promise((resolve, reject) => {
            this.$validateRecaptcha(resolve, reject)
          })
        }  else if (response.status === 400 && response.body.error === 56) {
          return new Promise((resolve, reject) => {
            this.g_isLoading = false
            reject(Error(response.body.error))
          })
        } else if (response.status === 0) {
          return new Promise((resolve, reject) => {
            this.g_dialog = {
              title: this.$ml.get('general_error'),
              message: this.$ml.get('general_no_internet'),
              show: true
            }
            this.g_isLoading = false
            reject(Error('Not connected to internet.'))
          })
        } else {
          return new Promise((resolve, reject) => {
            this.g_dialog = {
              title: this.$ml.get('general_error'),
              message: (response.body && response.body.description) || (response.body && response.body.error_description),
              show: true
            }
            this.g_isLoading = false
            reject(Error(response.body.description))
          })
        }
      },
      $authGetUser (redirect, resolve) {
        this.g_isLoading = true

        this.$api.getMe(
          response => {
            this.$store.dispatch('updateUser', response.body.data)
            // this.$store.dispatch('updateLanguage', response.body.data.locale)
            this.g_isLoading = false

            if (redirect) {
              this.$router.push(redirect)
            }

            if (resolve) {
              return resolve(true)
            }
          }, response => this.$errorHandling(response).then(() => {}, () => {})
        )
      },
      $authLogout () {
        this.g_isLoading = true
        this.$store.dispatch('logout')
        this.$router.push('/iniciarsesion')
        this.g_isLoading = false
      },
      $scrollTo (target) {
        this.$nextTick(() => {
          this.$vuetify.goTo(target, this.g_scrollOptions)
        })
      },
      $goTo (route, event) {
        if (event) {
          this.$analytics(event)
        }

        this.$router.push(route)
      },
      $analytics (event, data) {
        if (process.env.NODE_ENV !== 'production') {
          return
        }

        if (data) {
          if (window.fbq) window.fbq('trackCustom', event, data)
          if (window.gtag) window.gtag('event', event, data)
        } else {
          if (window.fbq) window.fbq('trackCustom', event)
          if (window.gtag) window.gtag('event', event)
        }
      }
    }
  }
</script>