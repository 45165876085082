import Vue from 'vue'

export default {
  getHouseCatalogs (callback, errorHandler) {
    return Vue.http.get('public/home/catalog').then(callback, errorHandler)
  },
  getHouseQuoteByCompany (company, quote, payment, level, plan, callback, errorHandler) {
    return Vue.http.get('public/home/quote/' + company + '/' + quote + '/' + payment + '/' + level + '/' + plan).then(callback, errorHandler)
  },
  createHouseQuote (payload, callback, errorHandler) {
    return Vue.http.post('public/home/quote', payload).then(callback, errorHandler)
  },
  getHouseQuote (uuid, callback, errorHandler) {
    return Vue.http.get('public/home/quote/' + uuid).then(callback, errorHandler)
  }
}
