import Vue from 'vue'

export default {
  requestQuote (payload, callback, errorHandler) {
    return Vue.http.post('public/quote/request', payload).then(callback, errorHandler)
  },
  validateDowntime (company, callback, errorHandler) {
    return Vue.http.get('public/downtime/' + company).then(callback, errorHandler)
  },
  buyWithCash (payload, callback, errorHandler) {
    return Vue.http.post('public/quote/cash', payload).then(callback, errorHandler) 
  },
  sendCampaingParams (payload, callback, errorHandler) {
    return Vue.http.post('public/quote/campaign', payload).then(callback, errorHandler)
  }
}
