import Vue from 'vue'

export default {
  forgotPassword (payload, callback, errorHandler) {
    return Vue.http.post('public/user/forgot/password', payload).then(callback, errorHandler)
  },
  resetPassword (payload, callback, errorHandler) {
    return Vue.http.post('public/user/reset/password', payload).then(callback, errorHandler)
  },
  createUser (payload, callback, errorHandler) {
    return Vue.http.post('public/user/new', payload).then(callback, errorHandler)
  },
  getMe (callback, errorHandler) {
    return Vue.http.get('user/view').then(callback, errorHandler)
  },
  editUser (payload, callback, errorHandler) {
    return Vue.http.put('user/edit', payload).then(callback, errorHandler)
  }
}
